import * as React from 'react';
import {SITE_TITLE} from '../consts';

const HeaderLink = props => {
    const {href, className, isActive, children} = props;

    return (
        <a 
            className={[className, { active: isActive }]} 
            {...props}
        >
        {children}
    </a>
    )
}

const Header = () => {
    const [open, setOpen] = React.useState(false);

    return (
        <header>
            <nav>
                <div className={"flex flex-wrap justify-between md:flex-nowrap md:gap-10 md:border-0 " + (open ? 'border-y-2 ': '')}>
                    <div
                        className={(open ? '' : 'hidden') + " order-1 w-full justify-center items-center md:order-none flex md:flex md:w-auto md:flex-1 md:flex-row md:justify-end"}
                    >
                        <HeaderLink
                            className="px-5 py-2 text-sm font-medium text-gray-600 hover:text-blue-500 dark:text-gray-400 md:inline text-center"
                            href="/"
                        >
                            Home
                        </HeaderLink>
                        <HeaderLink
                            className="px-5 py-2 text-sm font-medium text-gray-600 hover:text-blue-500 dark:text-gray-400 md:inline text-center"
                            href="/about"
                        >
                            Me
                        </HeaderLink>
                        <HeaderLink
                            className="px-5 py-2 text-sm font-medium text-gray-600 hover:text-blue-500 dark:text-gray-400 md:inline text-center"
                            href="/links"
                        >
                            Links
                        </HeaderLink>
                    </div>

                    <div className="flex w-full items-center justify-between md:w-auto px-8">
                        <a className="w-36 dark:hidden text-center text-xl uppercase" href="/">{SITE_TITLE}</a>
                        <button
                            onClick={() => setOpen(prev => !prev)}
                            aria-label="Toggle Menu"
                            className="ml-auto rounded-md px-2 py-1 text-gray-500 focus:text-blue-500 focus:outline-none dark:text-gray-300 md:hidden"
                            id="headlessui-disclosure-button-:r0:"
                            type="button"
                            aria-expanded="false"
                            data-headlessui-state=""
                        ><svg
                            className="h-6 w-6 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >{open && (
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                            />
                          )}
                          {!open && (
                            <path
                              fillRule="evenodd"
                              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                            />
                          )}
                        </svg>
                        </button>
                    </div>

                    <div
                        className={(open ? '' : 'hidden') + " order-2 w-full justify-center items-center md:justify-start flex md:flex md:order-none flex md:w-auto md:flex-1 md:flex-row"}
                    >
                        <a
                            className="px-5 py-2 text-sm font-medium text-gray-600 hover:text-blue-500 dark:text-gray-400 block md:inline text-center flex justify-center"
                            href="https://mastodon.uno/@diegobetto"
                            target="_blank"
                            rel="me"
                        >
                            <span className="sr-only">Follow Diego Betto on Mastodon</span>
                            <svg
                                viewBox="0 0 16 16"
                                aria-hidden="true"
                                width="24"
                                height="24"
                            ><path
                                fill="currentColor"
                                d="M11.19 12.195c2.016-.24 3.77-1.475 3.99-2.603.348-1.778.32-4.339.32-4.339 0-3.47-2.286-4.488-2.286-4.488C12.062.238 10.083.017 8.027 0h-.05C5.92.017 3.942.238 2.79.765c0 0-2.285 1.017-2.285 4.488l-.002.662c-.004.64-.007 1.35.011 2.091.083 3.394.626 6.74 3.78 7.57 1.454.383 2.703.463 3.709.408 1.823-.1 2.847-.647 2.847-.647l-.06-1.317s-1.303.41-2.767.36c-1.45-.05-2.98-.156-3.215-1.928a3.614 3.614 0 0 1-.033-.496s1.424.346 3.228.428c1.103.05 2.137-.064 3.188-.189zm1.613-2.47H11.13v-4.08c0-.859-.364-1.295-1.091-1.295-.804 0-1.207.517-1.207 1.541v2.233H7.168V5.89c0-1.024-.403-1.541-1.207-1.541-.727 0-1.091.436-1.091 1.296v4.079H3.197V5.522c0-.859.22-1.541.66-2.046.456-.505 1.052-.764 1.793-.764.856 0 1.504.328 1.933.983L8 4.39l.417-.695c.429-.655 1.077-.983 1.934-.983.74 0 1.336.259 1.791.764.442.505.661 1.187.661 2.046v4.203z"
                            ></path></svg>
                        </a>
                        <a
                            className="px-5 py-2 text-sm font-medium text-gray-600 hover:text-blue-500 dark:text-gray-400 block md:inline text-center flex justify-center"
                            href="https://twitter.com/diegobetto"
                            target="_blank"
                        >
                            <span className="sr-only">Follow Diego Betto on Twitter</span>
                            <svg
                                viewBox="0 0 16 16"
                                aria-hidden="true"
                                width="24"
                                height="24"
                            ><path
                                fill="currentColor"
                                d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
                            ></path></svg>
                        </a>
                        <a
                            className="px-5 py-2 text-sm font-medium text-gray-600 hover:text-blue-500 dark:text-gray-400 block md:inline text-center flex justify-center"
                            href="https://github.com/diego-betto"
                            target="_blank"
                        >
                            <span className="sr-only">Go to Diego Betto's GitHub repo</span>
                            <svg
                                viewBox="0 0 16 16"
                                aria-hidden="true"
                                width="24"
                                height="24"
                            ><path
                                fill="currentColor"
                                d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"
                            ></path></svg>
                        </a>
                    </div>
                </div>
            </nav>
        </header>

    )
}

export default Header;