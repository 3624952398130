// Place any global data in this file.
// You can import this data from anywhere in your site by using the `import` keyword.

export const SITE_TITLE = 'Diego Betto';
export const SITE_DESCRIPTION = 'News e articoli sul Web Development, JavaScript, Node.js, Fastify, React e sicurezza informatica';
export const SITE_DOMAIN = 'diegobetto.com'
export const SITE_URL = `https://${SITE_DOMAIN}`
export const SITE_AUTHOR = 'Diego Betto'
export const SOCIALS = [
    { title: 'X (Twitter)', url: 'https://twitter.com/diegobetto' },
    { title: 'Mastodon', url: 'https://mastodon.uno/@diegobetto' },
  ]
  export const TWITTER_HANDLE = 'diegobetto'